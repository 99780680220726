import './App.css';
import ParticlesBackground from './ParticlesBackground';
import Countdown from './Countdown';

function App() {
  return (
    <div className="App">
      <ParticlesBackground />
      <Countdown />
    </div>
  );
}

export default App;
