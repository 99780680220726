import React, { useState, useEffect } from 'react';

const Countdown = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date('2024-11-21T00:00:00'); // Set your target date here
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <div className="countdown">
      <h1>Launching Soon</h1>
      <p className="countdown-message">Hold tight! :) <br /> The stars are aligning for something great.</p>
      <div className="timer">
        {/* Always render all time boxes */}
        <div className="time-box">
          <span>{timeLeft.days || 0}</span>
          <p>Days</p>
        </div>
        <div className="time-box">
          <span>{timeLeft.hours || 0}</span>
          <p>Hours</p>
        </div>
        <div className="time-box">
          <span>{timeLeft.minutes || 0}</span>
          <p>Minutes</p>
        </div>
        <div className="time-box">
          <span>{timeLeft.seconds || 0}</span>
          <p>Seconds</p>
        </div>
      </div>
    </div>
  );
};

export default Countdown;