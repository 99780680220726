import React, { useEffect } from 'react';

const ParticlesBackground = () => {
  useEffect(() => {
    // Initialize particles.js using the global particlesJS function
    window.particlesJS('particles-js', {
      particles: {
        number: {
          value: 160, // Number of stars
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: '#ffffff', // Color of the stars
        },
        shape: {
          type: 'circle', // Shape of the particles (stars)
          stroke: {
            width: 0,
          },
        },
        opacity: {
          value: 1, // Stars opacity
          random: true, // Make opacity random for twinkling effect
          anim: {
            enable: true,
            speed: 1,
            opacity_min: 0,
            sync: false,
          },
        },
        size: {
          value: 3, // Size of stars
          random: true, // Random size for variety in the starfield
          anim: {
            enable: false,
            speed: 4,
            size_min: 0.3,
            sync: false,
          },
        },
        line_linked: {
          enable: false, // No connecting lines between stars
        },
        move: {
          enable: true,
          speed: 0.2, // Slow movement of stars to simulate floating
          direction: 'none',
          random: true,
          straight: false,
          out_mode: 'out',
          bounce: false,
        },
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: true,
            mode: 'bubble', // Stars "pop" when hovered over
          },
          onclick: {
            enable: true,
            mode: 'repulse', // Stars move away when clicked
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 250,
            size: 0,
            duration: 2,
            opacity: 0,
            speed: 3,
          },
          repulse: {
            distance: 400,
            duration: 0.4,
          },
        },
      },
      retina_detect: true,
    });
  }, []); // Empty array means this effect runs only once on mount

  return <div id="particles-js" style={{ width: '100%', height: '100vh', backgroundColor: '#000' }}></div>;
};

export default ParticlesBackground;